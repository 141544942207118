import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';

/**
 * Guard si no esta logeado lo manda fuera del sistema a /auth/login
 */
@Injectable({ providedIn: 'root' })
export class CanAccessGuard {
  private readonly userService = inject(UserService);
  private readonly token = inject(TokenService);
  private readonly router = inject(Router);
  private readonly window = inject(WINDOW);

  canActivateChild(): boolean | UrlTree {
    return this.canActivate();
  }

  canActivate(): boolean | UrlTree {
    const isTokenValid = this.token.get().isValid();
    const user = this.userService.get();

    if (isTokenValid && user) {
      // DESHABILITAMOS EL FORMULARIO PARA MOSTRAR VERIFICACION DEL EMAIL
      // if (user.email && !user.emailVerification) {
      //   return this.router.parseUrl('/verify-email');
      // }

      return true;
    }

    if (this.window['referral']) {
      const referral: string = this.window['referral'];
      if (!this.userService.get()) {
        const tree: UrlTree = this.router.parseUrl(`/membership?r=${referral}`);
        return tree;
      }
    }

    return this.router.parseUrl('/');
  }
}
