import { environment } from '@/environments/environment';
import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { FingerPrintService } from '../services/fingerprint.service';

export const fingerPrintInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const fingerPrintService = inject(FingerPrintService);
  const fp = fingerPrintService.$fingerprint();

  if (fp && req.url.includes(environment.apiUrl)) {
    const newReq = req.clone({
      headers: req.headers.append('X-Fingerprint', fp),
    });
    return next(newReq);
  }
  return next(req);
};
