export class Sound {
  private el: HTMLAudioElement | null = null;
  constructor(
    private source,
    private volume,
    private loop,
    private id = 'notification-sound',
  ) {}

  start() {
    const el = document.getElementById(this.id) as HTMLAudioElement;
    if (!el) {
      this.el = document.createElement('audio');
      this.el.setAttribute('src', this.source);
      this.el.setAttribute('hidden', 'true');
      this.el.setAttribute('id', this.id);
      this.el.setAttribute('volume', this.volume);
      this.el.setAttribute('autostart', 'false');
      this.el.setAttribute('loop', this.loop);
      this.el.classList.add('d-none');
      document.body.appendChild(this.el);
    } else {
      this.el = el;
    }
    this.el.play();
  }
  remove() {
    const el = document.getElementById(this.id);
    el?.remove?.();
  }
}

export default Sound;
