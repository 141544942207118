import {
  CursorPaginationResult,
  NotificationEntity,
  SearchNotificationsQueryDto,
} from '@admefy/domain';
import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from './rest.service';
import { TokenService } from './token.service';

declare const DeviceUUID: any;

/* *
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly _http = inject(RestService);
  private readonly tokenService = inject(TokenService);

  public searchNotifications(
    query: SearchNotificationsQueryDto,
  ): Observable<CursorPaginationResult<NotificationEntity>> {
    return this._http
      .get(`/v1/notifications/me`, query as unknown as Record<string, string>)
      .pipe(map((res) => res.body?.data));
  }

  public markAsReadNotifications(): Observable<void> {
    return this._http
      .patch(`/v1/notifications/me`)
      .pipe(map((res) => res.body?.data));
  }

  public deleteNotifications(): Observable<void> {
    return this._http
      .delete(`/v1/notifications/me`)
      .pipe(map((res) => res.body?.data));
  }

  public countUnreadNotifications(): Observable<number> {
    return this._http
      .get(`/v1/notifications/me/unread/count`)
      .pipe(map((res) => res.body?.data));
  }

  public markAsReadNotificationById(notificationId: string): Observable<void> {
    return this._http
      .patch(`/v1/notifications/${notificationId}`)
      .pipe(map((res) => res.body?.data));
  }

  public deleteNotificationById(notificationId: string): Observable<void> {
    return this._http
      .delete(`/v1/notifications/${notificationId}`)
      .pipe(map((res) => res.body?.data));
  }

  public subscribeWebPushDevice(
    subscription: PushSubscription,
  ): Observable<void> {
    if (!this.tokenService.get().isValid()) {
      return EMPTY;
    }

    let uuid = null;
    if (typeof DeviceUUID !== 'undefined') {
      uuid = new DeviceUUID().get();
    }

    return this._http
      .post(`/v1/notifications/me/web-push/subscribe/device`, {
        subscription: JSON.stringify(subscription),
        device: uuid,
      })
      .pipe(map((res) => res.body?.data));
  }
}
