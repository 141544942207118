import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';

/**
 * Guard si no esta logeado lo manda fuera del sistema a /auth/login
 */
@Injectable({ providedIn: 'root' })
export class IsEmailVerifiedGuard {
  private userService = inject(UserService);
  private router = inject(Router);

  canActivateChild(): boolean | UrlTree {
    return this.canActivate();
  }

  canActivate(): boolean | UrlTree {
    const user = this.userService.get();

    if (user) {
      return !user.emailVerification;
    }

    return this.router.parseUrl('/');
  }
}
