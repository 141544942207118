import {
  FollowingUserStatusType,
  MeEntity,
  PaginateResult,
  UpdateUserDto,
  UserBusiness,
  UserEntity,
} from '@admefy/domain';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalStateService } from './globalstate.service';
import { LocalStorage } from './local-storage.service';
import { HttpParamsType, RestService } from './rest.service';

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class UserService {
  private _http = inject(RestService);
  private gs = inject(GlobalStateService);
  private localStorage = inject(LocalStorage);

  public getLang(): string {
    return this.localStorage.getItem('lang') || '';
  }

  public setLang(lang: string) {
    this.localStorage.setItem('lang', lang);
  }

  public get(): MeEntity {
    const user: MeEntity = this.gs.get('user');
    if (user) {
      return user;
    }
    try {
      return JSON.parse(this.localStorage.getItem('my-user'));
    } catch (e) {
      return null;
    }
  }

  public set(user: MeEntity): void {
    this.gs.set('user', user);
    this.gs.set('theme', user?.themeMode || 'dark');
    if (user) {
      this.localStorage.setItem('my-user', JSON.stringify(user));
      return;
    }

    this.remove();
  }

  public remove(): void {
    this.localStorage.removeItem('my-user');
    this.gs.set('user', null);
  }

  public updateUser(body: UpdateUserDto): Observable<MeEntity> {
    return this._http
      .put(`/v1/user/me`, body)
      .pipe(map((res) => res?.body?.data));
  }

  public updateUserBusiness(body: Partial<UserBusiness>): Observable<MeEntity> {
    return this._http
      .put(`/v1/user/me/business`, body)
      .pipe(map((res) => res?.body?.data));
  }

  public getMe(): Observable<MeEntity> {
    return this._http.get(`/v1/user/me`).pipe(map((res) => res?.body?.data));
  }

  public getMyProviders(): Observable<UserEntity[]> {
    return this._http
      .get(`/v1/user/me/providers`)
      .pipe(map((res) => res?.body?.data));
  }

  public deleteUserBusiness(): Observable<MeEntity> {
    return this._http
      .delete(`/v1/user/me/business`)
      .pipe(map((res) => res?.body?.data));
  }

  public getUsersRecommended(): Observable<UserEntity[]> {
    return this._http
      .get(`/v1/user/recommended`)
      .pipe(map((res) => res?.body?.data));
  }

  public getLastBrands(): Observable<UserEntity[]> {
    return this._http
      .get(`/v1/user/lastbrands`)
      .pipe(map((res) => res?.body?.data));
  }

  public getAdmefyStaff(): Observable<MeEntity[]> {
    return this._http
      .get(`/v1/user/admefystaff`)
      .pipe(map((res) => res?.body?.data));
  }

  public getAdmefyStaffById(staffUserId: string): Observable<MeEntity> {
    return this._http
      .get(`/v1/user/admefystaff/${staffUserId}`)
      .pipe(map((res) => res?.body?.data));
  }

  public getMySubscriptions(
    params: HttpParamsType = null,
    cache: boolean | number = false,
  ) {
    return this._http
      .get(`/v1/user/me/subscriptions`, params, cache)
      .pipe(map((res) => res?.body?.data));
  }

  public getUser(
    userId: string,
    cache: boolean | number = false,
  ): Observable<UserEntity> {
    if (!userId) {
      return throwError(() => ({}));
    }
    return this._http
      .get(`/v1/user/${userId}`, null, cache)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Compruba si puede enviar directos
   * @param cardId
   */
  public canSendDirect(): Observable<true> {
    return this._http
      .get(`/v1/user/me/check_direct`)
      .pipe(map((res) => res?.body?.data));
  }

  public getUsersReferralsByMe(
    cache: boolean | number = false,
  ): Observable<UserEntity[]> {
    return this._http
      .get(`/v1/user/me/referrals`, null, cache)
      .pipe(map((res) => res?.body?.data));
  }

  // Borrar un usuario
  public deleteUser(): Observable<void> {
    return this._http.delete(`/v1/user/me`).pipe(map((res) => res?.body?.data));
  }

  /**
   * Obtenemos los seguidores de un usuario
   */
  public searchFollowersByUserId(
    userId: string,
    params: HttpParamsType = null,
  ): Observable<PaginateResult<UserEntity>> {
    if (!userId) {
      return throwError(() => ({
        data: {
          error: 'USER_ID_NOT_FOUND',
        },
      }));
    }
    return this._http
      .get(`/v1/user/${userId}/followers`, params)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * La función `getFollowersCount` recupera el número de seguidores de un ID de usuario determinado.
   * @param {string} userId - El parámetro `userId` es una cadena que representa el identificador único
   * de un usuario.
   * @returns un Observable que emite el recuento de seguidores para el ID de usuario dado.
   */
  getFollowersCountByUserId(userId: string): Observable<number> {
    if (!userId) {
      return throwError(() => ({
        data: {
          error: 'USER_ID_NOT_FOUND',
        },
      }));
    }
    return this._http
      .get(`/v1/user/${userId}/followers/count`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Obtenemos los siguiendo de un usuario
   * @param userId
   */
  public searchFollowingsByUserId(
    userId: string,
    params: HttpParamsType = null,
  ): Observable<PaginateResult<UserEntity>> {
    if (!userId) {
      return throwError(() => ({
        data: {
          error: 'USER_ID_NOT_FOUND',
        },
      }));
    }

    return this._http
      .get(`/v1/user/${userId}/followings`, params)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * La función `getFollowingsCount` recupera el recuento de seguidores de un ID de usuario
   * determinado.
   * @param {string} userId - El parámetro `userId` es una cadena que representa el identificador único
   * de un usuario.
   * @returns un Observable que emite el recuento de seguidores para el ID de usuario especificado.
   */
  getFollowingsCountByUserId(userId: string): Observable<number> {
    if (!userId) {
      return throwError(() => ({
        data: {
          error: 'USER_ID_NOT_FOUND',
        },
      }));
    }

    return this._http
      .get(`/v1/user/${userId}/followings/count`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Comienza a seguir a un amigo
   * @param userFollowId
   * @param accept
   */
  public respondFollowUser(
    userFollowId: string,
    accept: boolean,
  ): Observable<string> {
    return this._http
      .put(`/v1/user/me/follows/${userFollowId}?accept=${String(accept)}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Añade un usuario a la lista negra
   *
   * @param userBanId
   */
  public banUser(userBanId: string) {
    if (!userBanId) {
      return throwError(() => ({}));
    }
    return this._http
      .post(`/v1/user/me/black/${userBanId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Elimina un usuario de la lista negra
   *
   * @param userUnBanId
   */
  public unbanUser(userUnBanId: string) {
    if (!userUnBanId) {
      return throwError(() => ({}));
    }
    return this._http
      .delete(`/v1/user/me/black/${userUnBanId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Comprueba el estado de un usuario respecto a otro
   *
   * @param userFollowId
   */
  public checkStatusFollowingUser(
    userFollowId: string,
  ): Observable<FollowingUserStatusType> {
    if (!userFollowId) {
      return throwError(() => ({}));
    }

    return this._http
      .get(`/v1/user/me/follows/${userFollowId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Comienza a seguir a un amigo
   *
   * @param userFollowId
   */
  public followUser(userFollowId: string): Observable<{
    status: FollowingUserStatusType;
    user: UserEntity;
  }> {
    if (!userFollowId) {
      return throwError(() => ({}));
    }

    return this._http
      .post(`/v1/user/me/follows/${userFollowId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Elimina a un usuario de sus seguidores
   *
   * @param userFollowId
   */
  public unfollowUser(userFollowId: string): Observable<{
    status: FollowingUserStatusType;
    user: UserEntity;
  }> {
    if (!userFollowId) {
      return throwError(() => ({}));
    }

    return this._http
      .delete(`/v1/user/me/follows/${userFollowId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Añadir una pantalla a la lista de favoritos
   *
   * @param displayId
   */
  public addFavDisplay(displayId: string): Observable<void> {
    if (!displayId) {
      return throwError(() => ({}));
    }

    return this._http
      .post(`/v1/user/me/fav/${displayId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Elimina una pantalla a la lista de favoritos
   *
   * @param displayId
   */
  public deleteFavDisplay(displayId: string): Observable<void> {
    if (!displayId) {
      return throwError(() => ({}));
    }

    return this._http
      .delete(`/v1/user/me/fav/${displayId}`)
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Comprueba el finguerprint del usuario
   * @param userId
   * @param fingerprint
   * @returns
   */
  public checkFingerPrint(fingerprint: string) {
    return this._http
      .get(
        `/v1/user/me/fingerprint/check`,
        {
          fingerprint,
        },
        60,
      )
      .pipe(map((res) => res?.body?.data));
  }

  /**
   * Reseteamos devices conectados
   */
  public resetDevices(data: {
    username: string;
    password: string;
  }): Observable<void> {
    return this._http
      .post(`/v1/user/fingerprints/reset`, data)
      .pipe(map((res) => res?.body?.data));
  }

  public getReferralUsername(
    referralId: string,
  ): Observable<UserEntity | null> {
    if (!referralId) {
      return throwError(() => ({
        data: {
          error: 'REFERRAL_ID_NOT_FOUND',
        },
      }));
    }
    return this._http
      .get(`/v1/referral/${referralId}`)
      .pipe(map((res) => res?.body?.data));
  }
}
