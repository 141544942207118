import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface AppState {
  redirectUrl: string;
}

export const AppStore = signalStore(
  { providedIn: 'root' },
  withState<AppState>({
    redirectUrl: '',
  }),
  withMethods((store) => ({
    setRedirectUrl(url: string) {
      patchState(store, { redirectUrl: url });
    },
  })),
);
