import pack from '../../package.json';
import type { ConfigEnv } from '../../types/config';
const baseUrl = 'https://devel.admefy.com';
const invoiceUrl = 'https://invoice.devel.admefy.com';
const adminUrl = 'https://admin.devel.admefy.com';
const translationsUrl = 'https://api.admefy.com';
const playerUrl = 'https://player.devel.admefy.com';
const baseAPIUrl = 'https://api.devel.admefy.com';
const wsAPIUrl = 'https://ws.devel.admefy.com';
const imgProxyUrl = 'https://imgproxy.devel.admefy.com';
const analyticsProxyUrl = 'https://analytics.devel.admefy.com';
const staticsUrl = 'https://statics.devel.admefy.com';
const baseAPIUploadUrl = 'https://upload.devel.admefy.com';
const urlLive = 'https://live.devel.admefy.com';
const urlRTC = 'https://rtc.devel.admefy.com';
const seoUrl = 'https://seo.devel.admefy.com';
const env = 'development';
const imgproxyKey =
  '61420d554b21fdb78b8f889bf8bb979afd0fd1304f0ae8e7f54f6cc40b699b6e809be844ceb33c8112f0f278704c705e30351d627acb24eb78ab789c605917f5';
const imgproxySalt =
  '272a4ebb4fdee9a1d436b49454950ecba0293c43d0eebd0bc6632bed34189e42426bb048eaab0b09d41d7c75d2088b6d816e4b8115f0702ba722dc882e591f8a';

export const environment: ConfigEnv = {
  translationsUrl,
  version: pack.version,
  publicKeyWebPush:
    'BBPhyyLnb6eIr7zJ6z02W_6W9jrxRO5BdEdSoTJrCfuXONV1qQklzt35dKUzs6C6-aLXYwnl3Eu2j5GiD56Csdk',
  accessToken:
    'pk.eyJ1IjoiYWRzY3JlZW53b3JsZCIsImEiOiJjazAyOXkya3Aya2ZwM2x1dHd3Y29wZ2ppIn0.joMk21hH_bKadQ22X3EFAQ',
  production: false,
  env,
  stripePublicApiKey: 'pk_test_KOhY4ZGlzBzAEVYGY5bnmQLk00EZBRNgeH',
  apiUrl: `${baseAPIUrl}/api`,
  apiUploadUrl: `${baseAPIUploadUrl}/api`,
  analyticsProxyUrl,
  baseAPIUrl,
  playerUrl,
  urlLive,
  urlRTC,
  invoiceUrl,
  wsAPIUrl,
  baseUrl,
  seoUrl,
  adminUrl,
  imgProxyUrl,
  imgproxyKey,
  imgproxySalt,
  staticsUrl,
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYWRzY3JlZW53b3JsZCIsImEiOiJjazAyOXkya3Aya2ZwM2x1dHd3Y29wZ2ppIn0.joMk21hH_bKadQ22X3EFAQ',
  },
  centrifugo: 'wss://centrifugo.devel.admefy.com',
  giphyApi: 'S2ipICtAyyrcq1cQrPoUHny64aVxAFA2',
  tenorKey: 'AIzaSyAuEzO7cVsHyiaTrZOMQAvDZT_Za8xZN5M',
  creativeEditorKey:
    'LDEvlDZpaFCLegzGnxEdhUR481m57Kx-DXxGfLUc7YbM3u8LPEAWjUL1kVAf10Qo',
  google: {
    clientId:
      '104160184981-5gpm7d2tp82jffqar12qmahuuva590kc.apps.googleusercontent.com',
  },
  facebook: { clientId: '964577847206612' },
  trackerAnnouncePlayer: [
    'wss://p2p.devel.admefy.com',
    'wss://p2p-node.devel.admefy.com',
  ],
  recaptcha: {
    siteKey: '6LcdO9AUAAAAADeKDGIzUmUu_DnIkeFpL5CfV6hn',
  },
  rtsp: {
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
      {
        urls: 'turn:devel.admefy.com:3478',
        username: 'admefy',
        credential: 'admefy2021',
      },
    ],
  },
};
