import { UserEntity } from '@admefy/domain';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { UserService } from '../services/user.service';

export const userResolver: ResolveFn<UserEntity> = (
  route: ActivatedRouteSnapshot,
) => {
  const userService = inject(UserService);

  const userId = route.paramMap.get('userId');

  return userService.getUser(userId, true);
};
