import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';

/**
 * Guard si no esta logeado lo manda fuera del sistema a /auth/login
 */
@Injectable({ providedIn: 'root' })
export class CanSignUpGuard {
  private userService = inject(UserService);
  private token = inject(TokenService);
  private router = inject(Router);

  canActivateChild(): boolean | UrlTree {
    return this.canActivate();
  }

  canActivate(): boolean | UrlTree {
    if (!this.token.get().isValid()) {
      return true;
    }

    const user = this.userService.get();
    const path = '/' + user.username;
    const tree: UrlTree = this.router.parseUrl(path);
    return tree;
  }
}
