import { environment } from '@/environments/environment';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestService } from './rest.service';

export const supportedLanguages = {
  es: 'https://restcountries.eu/data/esp.svg', // Español
  // eu: 'https://upload.wikimedia.org/wikipedia/commons/2/2d/Flag_of_the_Basque_Country.svg', // Euskera
  en: 'https://restcountries.eu/data/gbr.svg', // Ingles
  // el: 'https://restcountries.eu/data/grc.svg', // griego
  ca: 'https://restcountries.eu/data/and.svg', // Catalan
  // da: 'https://restcountries.eu/data/dnk.svg', // Danes
  de: 'https://restcountries.eu/data/deu.svg', // Aleman
  // fi: 'https://restcountries.eu/data/fin.svg', // Filandes
  fr: 'https://restcountries.eu/data/fra.svg', // Frances
  it: 'https://restcountries.eu/data/ita.svg', // Italiano
  nl: 'https://restcountries.eu/data/nld.svg', // Holandes
  // no: 'https://restcountries.eu/data/nor.svg', // noruego
  pt: 'https://restcountries.eu/data/prt.svg', // portugues
  ru: 'https://restcountries.eu/data/rus.svg', // ruso
  // sv: 'https://restcountries.eu/data/ala.svg', // Servio
  zh: 'https://restcountries.eu/data/chn.svg', // chino
  // hi: 'https://restcountries.eu/data/ind.svg', // hindu
  ar: 'https://restcountries.eu/data/mar.svg', // arabe
  // uk: 'https://restcountries.eu/data/ukr.svg', // ukraniano
  // pl: 'https://restcountries.eu/data/pol.svg', // polaco
  // tr: 'https://restcountries.eu/data/tur.svg', // turco
  ja: 'https://restcountries.eu/data/jpn.svg', // japones
};

/**
 * Obtencion de las traducciones
 * */

export type TranslateResponse = {
  detectedLanguage?: {
    confidence: number;
    language: string;
  };
  translatedText: string;
};
@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly restService = inject(RestService);

  getTranslations(lang: string) {
    return this.restService
      .get(
        `/v1/translation/site/to/${lang}`,
        null,
        true,
        `${environment.translationsUrl}/api`,
      )
      .pipe(
        map((res) => {
          return res?.body?.data || {};
        }),
      );
  }

  admeetTranslate({ sourceLang, toLang, text }) {
    const ENDPOINT = 'https://translate.admefy.com/translate';

    return fetch(ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        q: (text ?? '').trim(),
        source: sourceLang,
        target: toLang,
        format: 'text',
        alternatives: 3,
        api_key: '',
      }),
    }).then((res) => res.json());
  }
}
