import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { DOCUMENT } from '@angular/common';

/** Socket factory */
export function SocketFactory(document: any) {
  return new SpinnerService(document);
}

export const ADMEFY_DOCUMENT = new InjectionToken<Document>('Document');

@NgModule({})
export class SpinnerModule {
  static forRoot(): ModuleWithProviders<SpinnerModule> {
    return {
      ngModule: SpinnerModule,
      providers: [
        { provide: ADMEFY_DOCUMENT, useExisting: DOCUMENT },
        {
          provide: SpinnerService,
          useFactory: SocketFactory,
          deps: [ADMEFY_DOCUMENT],
        },
      ],
    };
  }
}
