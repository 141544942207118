import { inject, Injectable } from '@angular/core';
import { TranslocoLoader } from '@jsverse/transloco';
import { TranslationService } from './app/core/services/translation.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly translationService = inject(TranslationService);

  getTranslation(lang: string) {
    return this.translationService.getTranslations(lang);
  }
}
