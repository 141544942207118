import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export type InternalStateType = {
  [key: string]: any;
};

@Injectable({ providedIn: 'root' })
export class GlobalStateService {
  private platformId = inject<object>(PLATFORM_ID);

  private _state: InternalStateType = {};
  private data = new BehaviorSubject<InternalStateType>(this._state);
  private subscriptions: Map<string, Array<Function>> = new Map<
    string,
    Array<Function>
  >();

  constructor() {
    this.data.asObservable().subscribe((data) => this._onEvent(data));
  }

  /**
   * Notifica a los subcriptores el cambio de un estado
   *
   * @param event
   * @param value
   */
  notifyDataChanged(event, value, force = false) {
    const previous = this.data[event];

    // Comprobamos si el valor a cambiado
    if (force || !isEqual(previous, value)) {
      this.data[event] = value;

      // Enviamos un push
      this.data.next({
        event,
        data: { previous, current: this.data[event] },
      });
    }
  }

  /**
   * Permite subscribirse a un evento
   *
   * @param event
   * @param callback
   */
  subscribe(event: string, callback: Function) {
    const subscribers = this.subscriptions.get(event) || [];
    subscribers.push(callback);

    this.subscriptions.set(event, subscribers);
  }

  _onEvent(data: any) {
    data = data || {};
    try {
      const subscribers = this.subscriptions.get(data.event) || [];

      subscribers.forEach((callback) => {
        callback.call(null, data.data);
      });
    } catch (err) {}
  }

  get state() {
    return (this._state = this._clone(this._state));
  }

  /**
   * No se permite la mutacion del state
   * @param value
   */
  set state(value) {
    throw new Error('No se puede setear el estado directamente');
  }

  /**
   * Obtiene una propiedad del state
   * @param prop
   * @returns {any}
   */
  get(prop?: any, def: any = null) {
    const state = this.state;
    if (!prop) {
      return state;
    }

    return state.hasOwnProperty(prop) ? state[prop] : def;
  }

  /**
   * Setea una propiedad en el state
   *
   * @param event
   * @param value
   * @returns {any}
   */
  set(event: string, value: any, good?: string): Promise<any> {
    return new Promise((resolve) => {
      if (!this._state) {
        return resolve(good);
      }

      // Notifica los cambios
      this.notifyDataChanged(event, value);
      this._state[event] = value;

      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          return resolve(good);
        }, 100);
      }
    });
  }

  /**
   * Clona un objeto borrando el binding
   *
   * @param object
   * @returns {any}
   * @private
   */
  private _clone(object: InternalStateType) {
    return JSON.parse(JSON.stringify(object));
  }
}
