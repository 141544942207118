import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { io } from 'socket.io-client';
import { SocketConfig } from './config/socket.config';

export class WrappedSocket {
  subscribersCounter = 0;
  ioSocket: any;
  emptyConfig: SocketConfig = {
    url: '',
    options: {},
  };

  constructor(
    config: SocketConfig,
    private platformId: object,
  ) {
    if (isPlatformBrowser(this.platformId) && typeof io !== 'undefined') {
      if (config === undefined) {
        config = this.emptyConfig;
      }
      const url: string = config.url;
      const options: any = config.options;
      this.ioSocket = io(url, options);
    }
  }

  of(namespace: string) {
    if (!this.ioSocket) {
      return;
    }

    this.ioSocket.of(namespace);
  }

  on(eventName: string, callback: Function) {
    if (!this.ioSocket) {
      return;
    }
    this.ioSocket.on(eventName, callback);
  }

  once(eventName: string, callback: Function) {
    if (!this.ioSocket) {
      return;
    }
    this.ioSocket.once(eventName, callback);
  }

  connect() {
    if (!this.ioSocket) {
      return;
    }
    return this.ioSocket.connect();
  }

  disconnect(close?: any) {
    if (!this.ioSocket) {
      return;
    }
    return this.ioSocket.disconnect.apply(this.ioSocket, arguments);
  }

  emit(eventName: string, data?: any, callback?: Function) {
    if (!this.ioSocket) {
      return;
    }
    return this.ioSocket.emit.apply(this.ioSocket, arguments);
  }

  removeListener(eventName: string, callback?: Function) {
    if (!this.ioSocket) {
      return;
    }
    return this.ioSocket.removeListener.apply(this.ioSocket, arguments);
  }

  removeAllListeners(eventName?: string) {
    if (!this.ioSocket) {
      return;
    }
    return this.ioSocket.removeAllListeners.apply(this.ioSocket, arguments);
  }

  fromEvent<T>(eventName: string): Observable<T> {
    this.subscribersCounter++;
    return new Observable<T>((observer: any) => {
      if (this.ioSocket) {
        this.ioSocket.on(eventName, (data: T) => {
          observer.next(data);
        });
      }
      return () => {
        if (this.subscribersCounter === 1)
          this.ioSocket.removeListener(eventName);
      };
    }).pipe(share());
  }

  fromOneTimeEvent<T>(eventName: string): Promise<T> {
    return new Promise<T>((resolve) => this.once(eventName, resolve));
  }
}
