/**
 * Service to control the global page spinner.
 */
export class SpinnerService {
  private loaders: Promise<any>[] = [];
  private selector: string | HTMLElement | null = null;

  constructor(private document) {}

  /**
   * Appends new loader to the list of loader to be completed before
   * spinner will be hidden
   * @param method Promise<any>
   */
  registerLoader(method: Promise<any>): void {
    this.loaders.push(method);
  }

  /**
   * Set new loader to the list of loader to be completed before
   * spinner will be hidden
   * @param method Promise<any>
   */
  setRegisterLoader(method: Promise<any>): void {
    this.loaders = [method];
  }

  /**
   * Clears the list of loader
   */
  clear(): void {
    this.loaders = [];
  }

  /**
   * Start the loader process, show spinnder and execute loaders
   */
  load(selector = '#global-spinner'): void {
    this.selector = selector;
    this.showSpinner();
    this.executeAll();
  }

  unload(selector = '#global-spinner'): void {
    this.selector = selector;
    this.hideSpinner();
    this.executeAll();
  }

  private executeAll(done = (values) => {}): void {
    Promise.all(this.loaders)
      .then((values) => {
        this.hideSpinner();
        done.call(null, values);
      })
      .catch((error) => {
        // TODO: Promise.reject
        console.error(error);
      });
  }

  // TODO is there any better way of doing this?
  private showSpinner(): void {
    const el = this.getSpinnerElement();
    if (el) {
      el.style['display'] = 'flex';
    }
  }

  private hideSpinner(): void {
    const el = this.getSpinnerElement();
    if (el) {
      el.style['display'] = 'none';
    }
  }

  private getSpinnerElement() {
    return typeof this.selector === 'string'
      ? this.document.querySelector(this.selector)
      : this.selector;
  }
}
