import dayjs from 'dayjs';

interface CacheData {
  data: any;
  expiration: Date | null;
}

export default class Cache {
  private cache = new Map<string, CacheData>();

  get(key: string) {
    const data = this.cache.get(key);
    if (!data) {
      return null;
    }

    if (data.expiration) {
      const now = new Date();
      if (now.getTime() - data.expiration.getTime() <= 0) {
        this.cache.delete(key);
        return null;
      }
    }

    return data.data;
  }

  set(key: string, data: any, expireSeconds = 0) {
    this.cache.set(key, {
      data,
      expiration:
        expireSeconds > 0
          ? dayjs().add(expireSeconds, 'second').toDate()
          : null,
    });
  }

  clear() {
    this.cache.clear();
  }
}
