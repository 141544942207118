import { Injectable, WritableSignal, signal } from '@angular/core';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

@Injectable({ providedIn: 'root' })
export class FingerPrintService {
  public $fingerprint: WritableSignal<string> = signal('');

  constructor() {
    this.getFingerprint();
  }

  private async getFingerprint() {
    try {
      const fp = await FingerprintJS.load();
      const result: GetResult = await fp.get();
      this.$fingerprint.set(result.visitorId);
    } catch (e) {}
  }
}
