import {
  NgModule,
  ModuleWithProviders,
  InjectionToken,
  PLATFORM_ID,
} from '@angular/core';
import { SocketConfig } from './config/socket.config';
import { WrappedSocket } from './socket.service';

/** Socket factory */
export function SocketFactory(config: SocketConfig, platformId: Object) {
  return new WrappedSocket(config, platformId);
}

export const SOCKET_CONFIG_TOKEN = new InjectionToken<SocketConfig>(
  '__SOCKET_IO_CONFIG__',
);

@NgModule({})
export class SocketModule {
  static forRoot(config: SocketConfig): ModuleWithProviders<SocketModule> {
    return {
      ngModule: SocketModule,
      providers: [
        { provide: SOCKET_CONFIG_TOKEN, useValue: config },
        {
          provide: WrappedSocket,
          useFactory: SocketFactory,
          deps: [SOCKET_CONFIG_TOKEN, PLATFORM_ID],
        },
      ],
    };
  }
}
